import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, ThemeProvider } from '@mui/material'
import {
  LibraryThemeProvider,
  flockTheme,
  TrackedFooter,
} from '@flock/shared-ui'
import * as Sentry from '@sentry/gatsby'
import {
  Core_LeadDocument,
  LandingGetLeadDocument,
  LandingGetSalesforceLeadFromLeadUuidDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatCityStateZip, localStore } from '@flock/utils'

import { useQuery } from '@apollo/client'
import { navigate } from 'gatsby'

import OfferPageWrapper from '../../components/OfferPageComponents/OfferPageWrapper'
import { isUuid, useRecordPageDuration } from '../../components/utils'
import { identify, shouldTrack } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'
import ErrorCard from '../../components/OfferPageComponents/ErrorCard'

import { PrelimOfferPageData } from '../../components/OfferPageComponents/offerPageTypes'
import PreliminaryOfferPage from '../../components/OfferPageComponents/PrelimOfferPageComponents/PreliminaryOfferPage'
import { Address } from '../../components/types'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

// todo: copied from lead_task.go. Figma specifies "condo" type though what is it?
const getPropertyType = (leadAnswers: any) => {
  let propertyType
  const leadAnswerPropertyType = leadAnswers.propertyType
  const { unitDetails } = leadAnswers
  switch (leadAnswerPropertyType) {
    case 'singlefamily':
      propertyType = 'Single-family detached home'
      break
    case 'townhouse':
      propertyType = 'Townhouse'
      break
    case 'condo':
      propertyType = 'Condo'
      break
    case 'multifamily':
      // eslint-disable-next-line no-case-declarations
      const unitCount = unitDetails.length
      switch (unitCount) {
        case 2:
          propertyType = 'Duplex'
          break
        case 3:
          propertyType = 'Triplex'
          break
        case 4:
          propertyType = 'Fourplex'
          break
        default:
          propertyType = 'Multifamily'
          break
      }
      break
    case 'duplex':
      propertyType = 'Duplex'
      break
    case 'triplex':
      propertyType = 'Triplex'
      break
    case 'fourplex':
      propertyType = 'Fourplex'
      break
    default:
      propertyType = ''
  }
  return propertyType
}

type PropertyEstimateProps = {
  params: {
    lead: string
  }
}

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  const { lead: leadUuid } = params
  useRecordPageDuration()

  const invalidUuid = typeof window !== 'undefined' && !isUuid(leadUuid)

  const [loading, setLoading] = useState<boolean>(true)

  const [pageData, setPageData] = useState<Partial<PrelimOfferPageData>>({})
  const [error, setError] = useState(false)
  const { refetch: refetchSalesforceLead } = useQuery(
    LandingGetSalesforceLeadFromLeadUuidDocument,
    {
      skip: true,
    }
  )
  const { refetch: refetchLead } = useQuery(LandingGetLeadDocument, {
    skip: true,
  })

  const initializePageData = useCallback(async () => {
    // If there exists a SFDC lead that's associated to the lead uuid, reroute to the SFDC lead
    let salesforceLeadDataResult = null
    try {
      const { data: refetchSalesforceLeadDataResult } =
        await refetchSalesforceLead({
          input: {
            leadUuid,
          },
        })
      salesforceLeadDataResult = refetchSalesforceLeadDataResult
      if (
        salesforceLeadDataResult?.getSalesforceLeadFromLeadUuid?.salesforceLead
          ?.id
      ) {
        navigate(
          `/lead-estimate/${salesforceLeadDataResult?.getSalesforceLeadFromLeadUuid?.salesforceLead?.id}`
        )
      }
    } catch (e) {
      // Continue to query for Flock lead information
    }

    let leadDataResult = null
    try {
      const { data: refetchLeadDataResult } = await refetchLead({
        input: {
          leadUuid,
        },
      })
      leadDataResult = refetchLeadDataResult
    } catch (e) {
      setError(true)
      setLoading(false)
      return
    }

    if (
      !leadDataResult?.lead?.lead?.email &&
      !leadDataResult?.lead?.lead?.phoneNumber &&
      localStore?.getItem('disableTracking') !== 'true'
    ) {
      Sentry.captureException(
        new Error(`Offer page viewed for a lead without an email or phone`),
        {
          tags: {
            critical: true,
          },
          extra: {
            leadUuid,
          },
        }
      )
    }

    const customerUuid = leadDataResult?.lead?.lead?.customerUuid
    if (
      shouldTrack() &&
      customerUuid &&
      customerUuid !== '00000000-0000-0000-0000-000000000000' &&
      !localStore?.getItem('customerUuid')
    ) {
      identify({
        userId: customerUuid as string,
      })

      localStore?.setItem('customerUuid', customerUuid)
    }

    const updatedPageData: Partial<PrelimOfferPageData> = {}

    const leadData = leadDataResult?.lead?.lead || {}

    let parsedJsonAnswers: { [key: string]: any } = {}
    if (leadData?.answers) {
      parsedJsonAnswers = JSON.parse(leadData?.answers)
    }

    updatedPageData.fullName = leadData.fullName || ''

    const displayFullName = updatedPageData.fullName.replace(' Unknown', '')
    updatedPageData.fullName = displayFullName

    updatedPageData.phoneNumber = leadData.phoneNumber as string

    updatedPageData.street = leadData.address?.street as string
    updatedPageData.state = leadData.address?.state as string
    updatedPageData.city = leadData.address?.city as string
    updatedPageData.latitude = leadData.address?.latitude as number
    updatedPageData.longitude = leadData.address?.longitude as number
    const cityStateZip = formatCityStateZip(leadData?.address as Address)
    updatedPageData.cityStateZipcode = cityStateZip

    updatedPageData.bedrooms = parsedJsonAnswers.bedCount
    updatedPageData.bathrooms = parsedJsonAnswers.bathCount
    updatedPageData.halfBaths = parsedJsonAnswers.halfBathCount

    const hasUnitDetails = parsedJsonAnswers.unitDetails?.length > 0
    if (hasUnitDetails) {
      let summedBedrooms = 0
      let summedBathrooms = 0
      parsedJsonAnswers.unitDetails.forEach((details: any) => {
        summedBedrooms += Number(details?.bedCount || details?.bedrooms || 0)
        summedBathrooms += Number(
          details?.bathCount || details?.full_bathrooms || 0
        )
      })
      updatedPageData.bedrooms = summedBedrooms
      updatedPageData.bathrooms = summedBathrooms
    }
    updatedPageData.propertyCondition = parsedJsonAnswers.propertyCondition
    updatedPageData.propertyTypeDisplayString =
      getPropertyType(parsedJsonAnswers)

    if (leadData?.documents?.length) {
      updatedPageData.leadDocuments = leadData.documents as Core_LeadDocument[]
    }

    if (leadData?.slackThreadUrl) {
      updatedPageData.slackThreadUrl = leadData.slackThreadUrl
    }

    setPageData(updatedPageData)
    setLoading(false)
  }, [leadUuid, refetchLead, refetchSalesforceLead])

  useEffect(() => {
    if (!invalidUuid) {
      initializePageData()
    }
  }, [initializePageData, invalidUuid])

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        new Error(`Prelim-estimate failed to load for lead`),
        {
          tags: {
            critical: true,
          },
          extra: {
            leadUuid,
          },
        }
      )
    }
  }, [error])

  if ((invalidUuid || error) && !loading) {
    return (
      <ThemeProvider theme={flockTheme}>
        <LibraryThemeProvider>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
          >
            <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <>
      <OfferPageWrapper leadUuid={leadUuid}>
        {loading ? (
          <Box
            height="auto"
            pt="50px"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <Box
            height="auto"
            position="relative"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <PreliminaryOfferPage
              userId={leadUuid}
              pageData={pageData as PrelimOfferPageData}
            />
          </Box>
        )}

        <SectionLayout name="footer" backgroundColor="gray1.main">
          <Grid item xs={12}>
            <TrackedFooter />
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
